<template>
  <v-card outlined class="pa-4">
    <v-card-title class="font-weight-bold">Sales Component</v-card-title>
    <PieChart :chartData="chartData" :options="options"></PieChart>
  </v-card>
</template>

<script>
export default {
  name: "PieChartArea",
  data: () => ({}),
  components: {
    PieChart: () => import("@/components/chart/PieChart"),
  },
  computed: {
    chartData() {
      return this.$store.getters.summaryPieChartData;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    options() {
      const fontColor = this.$vuetify.theme.themes[this.theme].chartFont;
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: fontColor,
          },
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              const tooltip_index = tooltipItem.index;
              const pie_data = data.datasets[tooltipItem.datasetIndex].data;
              const pie_labels = data.datasets[tooltipItem.datasetIndex].labels;
              const current_data = pie_data[tooltip_index];
              const current_label = pie_labels[tooltip_index];
              return `${current_label}: ${current_data}%`;
            },
          },
        },
      };
    },
  },
};
</script>

<style scoped></style>
